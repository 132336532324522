.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
}

.logo {
  background-color: #ffffff;
}

.logo img {
  background-color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.logo .loginn-container {
  float: right;
  padding-top: 8px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 8px;
  margin-right: 10px;
  background-color: #c4d3e6;
  font-size: 17px;
  font-weight: bolder;
  font-style: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border: none;
  cursor: pointer;
  border: solid;
  border-color: #c4d3e6;
  border-style: outset;
}

.logo .logged-in-container {
  float: right;
  padding-top: 5px;
  padding-bottom: 0px;
  padding-right: 10px;
  margin-top: 2px;
  margin-right: 2px;
  font-size: 20px;
  font-weight: lighter;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border: none;
  cursor: pointer;
}

.topnav {
  overflow: hidden;
  background-color: #355681;
}

.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding-top: 14px;
  padding-right: 16px;
  padding-bottom: 14px;
  padding-left: 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #04aa6d;
  color: white;
}

.topnav .login-container {
  float: right;
}

.topnav input[type="text"] {
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border: none;
  width: 120px;
}

.topnav input[type="password"] {
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border: none;
  width: 120px;
}

.topnav .login-container button {
  float: right;
  padding: 6px 8px;
  margin-top: 8px;
  margin-right: 10px;
  background-color: blanchedalmond;
  color: black;
  font-size: 17px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border: none;
  cursor: pointer;
}

.topnav .login-container button:hover {
  background-color: green;
}

.loginbutton {
  float: right;
  border: none;
  cursor: pointer;
}

.loginbutton:hover {
  background-color: greenyellow;
}

@media screen and (max-width: 600px) {
  .topnav .login-container {
    float: none;
  }
  .topnav a,
  link,
  .topnav input[type="text"],
  .topnav input[type="password"],
  .topnav .login-container button {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
  }
  .topnav input[type="text"] {
    border: 1px solid #ccc;
  }
  .topnav input[type="password"] {
    border: 1px solid #ccc;
  }
}

.sidenav {
  height: 100%;
  width: 200px;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 10;
  background-color: #ddd4d4;
  overflow-x: auto;
}

.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  color: Black;
  display: block;
}

.sidenav a:hover {
  color: Red;
}

.sideimg {
  height: 100%;
  width: 450px;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 15;
  padding-top: 10px;
  overflow-x: auto;
}

.content {
  height: 100%;
  min-height: 80vh;
  position: relative;
}

.home {
  margin-left: 450px;
}

.main img {
  width: 450px;
  height: 500px;
  float: left;
  padding-top: 10px;
  padding-right: 10px;
}

.detail {
  padding-top: 2px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 15px;
  color: #355681;
  text-align: left;
}

.main {
  margin-left: 200px;
}

.main h1 {
  vertical-align: middle;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  position: relative;
  font-size: 15px;
  color: #355681;
  text-align: center;
  padding-left: 200px;
}

.main input[type="text"] {
  padding: 6px;
  margin-top: 8px;
  margin-bottom: 10px;
  font-size: 17px;
  border: 2px solid black;
  width: 340px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

table.homeworks {
  position: relative;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 95%;
}

table.homeworks td,
table.homeworks th {
  border: 1px solid #ddd;
  padding: 8px;
}

table.homeworks tr:nth-child(even) {
  background-color: #f2f2f2;
}

table.homeworks tr:hover {
  background-color: #ddd;
}

table.homeworks th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  background-color: #4caf50;
  color: white;
  font-size: 18px;
}

table.homeworks td {
  font-size: 15px;
}
